*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.absolute {
  position: absolute;
}

.m-2 {
  margin: .5rem;
}

.m-4 {
  margin: 1rem;
}

.m-auto {
  margin: auto;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.h-auto {
  height: auto;
}

.w-14 {
  width: 3.5rem;
}

.w-3\/12 {
  width: 25%;
}

.w-6\/12 {
  width: 50%;
}

.w-9\/12 {
  width: 75%;
}

.w-full {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.rounded-lg {
  border-radius: .5rem;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.text-orange-400 {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

* {
  margin: 0;
}

.body {
  background-color: #f8f8f8;
  margin: 20px;
  font-family: Poppins, sans-serif;
}

.header {
  color: #fff;
  background-color: #fc8019;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  display: flex;
  box-shadow: 0 2px 4px #0000001a;
}

.logo img {
  max-width: 100%;
  max-height: 40px;
}

.nav-items ul {
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.nav-items li {
  cursor: pointer;
  color: #fff;
  margin: 0 15px;
  font-weight: bold;
}

.nav-items li:hover {
  text-decoration: underline;
}

.search {
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 80%;
  max-width: 400px;
  margin: 20px 0;
  padding: 10px;
  font-size: 1em;
}

.res-list {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.res-card {
  background-color: #f4f2ec;
  border: 5px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: space-between;
  width: 250px;
  height: 300px;
  margin: 20px;
  padding: 15px;
  display: flex;
  box-shadow: 0 2px 4px #0000001a;
}

.res-card:hover {
  opacity: .8;
}

.food-image img {
  border-radius: 8px;
  width: 100%;
  height: 13rem;
}

.food-heading {
  margin-top: 10px;
  font-size: 1.2em;
  font-weight: bold;
}

.footer {
  color: #fff;
  text-align: center;
  background-color: #fc8019;
  padding: 20px;
}

.filter-btn {
  cursor: pointer;
  background: #55600e;
  margin: 10px;
}

.login-btn {
  box-sizing: border-box;
  border: 2px dotted gray;
  width: 150px;
  height: 50px;
  margin: 10px;
  padding: 10px;
  transition: transform .2s;
}

.login-btn:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.filter {
  display: flex;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid #0000;
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-loading .slick-list {
  background: #fff url("ajax-loader.32c19c5c.gif") center no-repeat;
}

@font-face {
  font-family: slick;
  font-weight: normal;
  font-style: normal;
  src: url("slick.019ff462.eot");
  src: url("slick.019ff462.eot#iefix") format("embedded-opentype"), url("slick.c8d05265.woff") format("woff"), url("slick.441ccdd6.ttf") format("truetype"), url("slick.0ba9c062.svg#slick") format("svg");
}

.slick-prev, .slick-next {
  cursor: pointer;
  color: #0000;
  background: none;
  border: none;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  color: #0000;
  background: none;
  outline: none;
}

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before, .slick-next:before {
  opacity: .75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: slick;
  font-size: 20px;
  line-height: 1;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  bottom: -25px;
}

.slick-dots li {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.slick-dots li button {
  cursor: pointer;
  color: #0000;
  background: none;
  border: 0;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 5px;
  font-size: 0;
  line-height: 0;
  display: block;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  content: "•";
  text-align: center;
  opacity: .25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 20px;
  height: 20px;
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: #000;
}

/*# sourceMappingURL=index.6647fddf.css.map */
