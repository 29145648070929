/* styles.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


*{
  margin: 0;
  
 }
.body {
  margin: 20px;
  font-family: 'Poppins', sans-serif;
  background-color: #f8f8f8;
  
}

.header {
  background-color: #fc8019;
  color: #fff;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo img {
  max-width: 100%;
  max-height: 40px;
}

.nav-items ul {
  
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.nav-items li {
  margin: 0 15px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
}

.nav-items li:hover {
  text-decoration: underline;
}


.search {
  margin: 20px 0;
  padding: 10px;
  width: 80%;
  max-width: 400px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1em;
}

.res-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
/* Adjusted to space items evenly */
}

.res-card {
  background-color: #f4f2ec;
  border: 1px solid #ddd;
  border:5px;
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 300px;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px
}

.res-card:hover {
  opacity: 0.8;
}

.food-image img {
  width: 100%; 
  height: 13rem;
 
  border-radius: 8px;
}

.food-heading {
  margin-top: 10px;
  font-size: 1.2em;
  font-weight: bold;
}

.footer {
  background-color: #fc8019;
  color: #fff;
  padding: 20px;
  text-align: center;
}

/* Add more styles as needed */
.filter-btn{
  margin: 10px;
  background: #55600e;
  cursor:pointer;

}

.login-btn {
  width: 150px; /* Set a fixed width */
  height: 50px; /* Set a fixed height */
  padding: 10px;
  margin: 10px;
  border: 2px dotted grey;
  box-sizing: border-box; /* Include padding and border in the total width and height */
  transition: transform 0.2s; /* Add a smooth transition for the transform property */
}

.login-btn:hover {
  cursor: pointer;
  transform: scale(1.05); /* Scale the button on hover */
}
.filter{
  display: flex;
}